import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { v4 as uuid } from "uuid";
import { Expander, Grid, Link, WaveImage, Image, SVG } from "~components";
import { breakpoint } from "~utils/css.js";
import { getFallbackImageSrc } from "~utils/helpers";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  width: 100%;
  height: auto;
  min-height: 105vw;
  position: relative;
  display: block;
  padding: 2.5rem 0;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};

  ${breakpoint(`tablet`)} {
    min-height: 80vw;
    padding: 3.5rem 0;
  }

  ${breakpoint(`large-tablet`)} {
    min-height: 56.59722222vw;
    padding: 3.75rem 0;
  }
`;

const Figure = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
`;

const Header = styled.header`
  grid-column: 1 / -1;
  position: relative;
  white-space: normal;

  ${breakpoint(`tablet`)} {
    grid-column: 1 / span 4;
    white-space: pre-wrap;
  }

  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 6;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 2 / span 5;
  }
`;

const ListContainer = styled.div`
  grid-column: 1 / -1;
  position: relative;
  display: flex;
  align-items: start;
  justify-content: end;

  ${breakpoint(`tablet`)} {
    grid-column: 5 / span 4;
  }

  ${breakpoint(`large-tablet`)} {
    grid-column: 8 / span 5;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 8 / span 4;
  }
`;

const List = styled.ul`
  width: 100%;
  position: relative;
  margin-top: 2.5rem;

  ${breakpoint(`tablet`)} {
    margin-top: 0;
  }
`;

const ListItem = styled.li`
  width: 100%;
  position: relative;
  margin-bottom: 0.75rem;
`;

const ValueWrapper = styled.div`
  position: relative;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5rem;

  color: var(--color-primary-orange-100);

  svg {
    fill: var(--color-primary-orange-100);
  }

  ${breakpoint(`large-tablet`)} {
    flex-direction: row;
    align-items: center;
  }
`;

const LinkStatus = styled.p`
  margin-bottom: 1rem;

  ${breakpoint(`large-tablet`)} {
    margin-bottom: 0;
  }
`;

const ArticleLinks = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  justify-content: space-between;

  ${breakpoint(`large-tablet`)} {
    flex-direction: row;
    align-items: center;
  }
`;

/** ============================================================================
 * @component
 */
const LiquidExpanders = ({ data: { backgroundColour, fontColour, heading, image, articles, effectActive } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const containerRef = useRef();

  const [expandedIndex, setExpandedIndex] = useState(null);

  // ---------------------------------------------------------------------------
  // variables

  const imageSrc = getFallbackImageSrc(image);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container ref={containerRef} background={backgroundColour?.hex} color={fontColour?.hex}>
      <Figure>
        {effectActive ? (
          <WaveImage id="liquid-expanders-image" imageUrl={imageSrc} />
        ) : (
          <Image
            image={image}
            css={css`
              height: 100%;
            `}
          />
        )}
      </Figure>

      {/* // */}

      <Content>
        <Grid>
          <Header>
            <h2
              css={css`
                white-space: pre-wrap;
              `}
              className="h1"
            >
              {heading}
            </h2>
          </Header>

          <ListContainer>
            <List>
              {articles?.[0] &&
                articles.map((article, index) => {
                  const active = expandedIndex === index;

                  const value = (
                    <ValueWrapper>
                      <p className="b1">{article.body}</p>

                      {article?.footnote && (
                        <p
                          css={css`
                            margin-top: 0.75rem;
                          `}
                          className="b2"
                        >
                          {article.footnote}
                        </p>
                      )}

                      <LinksContainer>
                        <LinkStatus className="b2">{article.status}</LinkStatus>

                        <ArticleLinks>
                          {article?.links?.[0] &&
                            article.links.map((link) => (
                              <li key={link?._key}>
                                <Link
                                  key={`article-link-${uuid()}`}
                                  to={link?.url}
                                  css={css`
                                    position: relative;
                                    margin-right: 1.5rem;

                                    display: flex;

                                    text-transform: uppercase;

                                    ${breakpoint(`large-tablet`)} {
                                      align-items: center;
                                      justify-content: center;
                                    }
                                  `}
                                >
                                  <p className="b2">{link?.text}</p>

                                  <SVG
                                    svg="arrowRight"
                                    css={css`
                                      width: 11px;
                                      margin-left: 10px;
                                    `}
                                  />
                                </Link>
                              </li>
                            ))}
                        </ArticleLinks>
                      </LinksContainer>
                    </ValueWrapper>
                  );

                  return (
                    <ListItem key={article?._key}>
                      <Expander
                        active={active}
                        expander={{ key: article.name, value }}
                        onExpand={() => {
                          setExpandedIndex(active ? null : index);
                        }}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </ListContainer>
        </Grid>
      </Content>
    </Container>
  );
};

export default LiquidExpanders;
